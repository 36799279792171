import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { NotFound } from "./pages";

import Page from "./pages/Page";
import Frontpage from "./pages/Frontpage";
/* import Footer from "./components/Footer"; */
import "antd/dist/antd.css";

const App = (props) => {
  return (
    <>
      {/* <Header></Header> */}

      <div style={{ minHeight: "calc(100vh - 135px)" }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Frontpage} />
            <Route exact path="/page/:uid" component={Page} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </div>

      {/* <Footer>
        <div style={{ textAlign: "center" }}></div>
      </Footer> */}
    </>
  );
};

export default App;
