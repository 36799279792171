import React, { Component } from "react";
import "./HeroSection.scss";

export class HeroSection extends Component {
  componentDidMount() {
    this.setState({
      backgroundImage: this.props.backgroundImage,
    });
  }

  render() {
    let { backgroundImage, heightSubtract, vheight, overlay } = this.props;
    backgroundImage = backgroundImage ? "url(" + backgroundImage + ")" : "";
    vheight = vheight || 100;
    const height = heightSubtract
      ? "calc(" + vheight + "vh - " + heightSubtract + ")"
      : vheight + "vh";
    let className = overlay ? "HeroSection hero_overlay" : "HeroSection";
    className = className + " " + this.props.className;

    return (
      <>
        <section
          id={this.props.id}
          className={className}
          style={{
            backgroundImage: backgroundImage,
            minHeight: height,
            backgroundColor: this.props.backgroundColor || "",
          }}
        >
          <div
            className="innerContainer"
            style={this.props.innerContainerStyle}
          >
            <div className="mainContent">{this.props.children}</div>
          </div>
        </section>
      </>
    );
  }
}

export default HeroSection;
