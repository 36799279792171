import React from "react";
import HeroSection from "../components/HeroSection";
import { Image, Tabs, Typography } from "antd";
import "./Frontpage.scss";

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

export default function Frontpage() {
  return (
    <>
      <HeroSection vheight={100} backgroundColor="#000" className="flex-end">
        <>
          <div className="main_title_container text-center">
            <Title className="mainTitle text-center white-color mt0">
              ICELAND
            </Title>
            <img src="/images/eurovision_logo.png" alt="Eurovision logo" />
            <Title className="mainTitle dadiTitle text-center white-color mt0">
              Daði og Gagnamagnið - 10 YEARS
            </Title>
          </div>

          <div id="skrimsli_container">
            <img
              className="skrimsli_base"
              style={{ width: "100%" }}
              src="/images/skrimsli_base.jpg"
              alt=""
            />
            <img
              className="skrimsli_head"
              src="/images/skrimsli_head.png"
              alt=""
            />
          </div>
        </>
      </HeroSection>

      <HeroSection vheight={100}>
        <Title>
          <div className="text-center">Music Videos</div>
        </Title>
        <Tabs defaultActiveKey="tenyears" centered onChange={() => {}}>
          <TabPane tab="10 years" key="tenyears">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="tenyears"
                  src="https://www.youtube.com/embed/1zTbVRPh5EI"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Think About Things" key="think">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="thinkaboutthings"
                  src="https://www.youtube.com/embed/VFZNvj-HfBU"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Feel the Love" key="feelthelove">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="feelthelove"
                  src="https://www.youtube.com/embed/2Ujj03vQTjI"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Is This Love?" key="isthislove">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="isthislove"
                  src="https://www.youtube.com/embed/glzArwowVo0"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Think About Things (Live)" key="thinklive">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="thinkaboutthingslive"
                  src="https://www.youtube.com/embed/1HU7ocv3S2o"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </HeroSection>

      <HeroSection
        vheight={80}
        className="text-center flex-end"
        backgroundColor="#000"
      >
        <Title>
          <div style={{ color: "#fff" }}>Social</div>
        </Title>

        <div id="social_icons">
          <a
            href="https://open.spotify.com/artist/3Hb64DQZIhDCgyHKrzBXOL?si=WQhIt7r8TdmLwYccQ4ndHg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/spotify.svg" alt="Social Logo" />
          </a>

          <a
            href="https://music.apple.com/us/artist/dadi-freyr/1256781645"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/apple_music.svg" alt="Social Logo" />
          </a>

          <a
            href="https://www.youtube.com/channel/UCF18N219OPiOcElz_hSYoIQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/youtube.svg" alt="Social Logo" />
          </a>

          <a
            href="https://www.facebook.com/dadimakesmusic/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/fb.svg" alt="Social Logo" />
          </a>

          <a
            href="https://discord.gg/4ZwmWwSfcq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/discord.svg" alt="Social Logo" />
          </a>

          <a
            href="https://twitter.com/dadimakesmusic"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/twitter.svg" alt="Social Logo" />
          </a>

          <a
            href="https://www.instagram.com/dadimakesmusic/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/instagram.svg" alt="Social Logo" />
          </a>

          <a
            href="https://www.tiktok.com/@dadimakesmusic"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/tiktok.svg" alt="Social Logo" />
          </a>
        </div>
        <img
          src="/images/group.jpg"
          style={{ width: "80%", maxWidth: 1200 }}
          alt="Group"
        />
      </HeroSection>

      <HeroSection
        vheight={85}
        backgroundColor="#fff"
        className="white-color text-center"
      >
        <Title>10 years - Lyrics</Title>

        <Tabs defaultActiveKey="lyrics_english" centered onChange={() => {}}>
          <TabPane tab="English" key="lyrics_english">
            <Paragraph id="lyricsContainer">
              <p>
                We've been together for a decade now Still everyday I'm lovin'
                you more If I could do it all again I'd probably do it all the
                same as before I don't wanna know what would've happened if I
                never had had your love I didn't become myself before I met you
                I don't wanna know what would've happened if I never had felt
                your love Everything about you I like
              </p>
              <p>
                We started out so fast Now we can take it slower Love takes some
                timе Takes a little time, so takе a little time As it ages like
                wine.
              </p>
              <p>
                How does it keep getting better? Everyday our love finds a new
                way to grow The time we spend together Hmm, simply feels good We
                got a good thing going
              </p>
              <p>
                And just when I thought that my heart was full I found places
                that I never explored You're so fascinating And I can't remember
                the last time I was bored I don't wanna know what would've
                happened if I never had had your love I didn't become myself
                before I met you I don't wanna know what would've happened if I
                never had felt your love Everything about you I like
              </p>
              <p>
                How does it keep getting better? Everyday our love finds a new
                way to grow (Finds a new way to grow) The time we spend together
                Hmm, feels good
              </p>
              <p>
                How does it keep getting better? Every day our love finds a new
                way to grow The time we spend together Hmm, simply feels good We
                got a good thing going
              </p>
            </Paragraph>
          </TabPane>

          <TabPane tab="Italian" key="lyrics_italian">
            <Paragraph id="lyricsContainer">
              <p>
                Stiamo insieme ormai da dieci anni Eppure ti amo ogni giorno di
                più Se potessi tornare indietro Credo che rifarei tutto uguale.
              </p>

              <p>
                Non voglio sapere cosa sarebbe successo se non avessi avuto il
                tuo amore Prima d’incontrarti non ero me stesso Non voglio
                sapere cosa sarebbe successo se non avessi provato il tuo amore
                Mi piace tutto di te
              </p>

              <p>
                Abbiamo cominciato di corsa Ora possiamo andare piano L’amore
                necessita di tempo, un po’ di tempo, quindi fai con calma Mentre
                invecchia come il vino
              </p>

              <p>
                Migliora sempre più Ogni giorno il nostro amore trova nuovi modi
                per crescere Il tempo che trascorriamo insieme È così bello
                Abbiamo qualcosa di speciale
              </p>

              <p>
                E proprio quando pensavo che il mio cuore fosse colmo Ho trovato
                angoli inesplorati Sei così affascinante Non riesco a ricordare
                l’ultima volta che mi sono annoiato
              </p>

              <p>
                Non voglio sapere cosa sarebbe successo se non avessi avuto il
                tuo amore Prima d’incontrarti non ero me stesso Non voglio
                sapere cosa sarebbe successo se non avessi provato il tuo amore
                Mi piace tutto di te
              </p>

              <p>
                Migliora sempre più Ogni giorno il nostro amore trova nuovi modi
                per crescere Il tempo che trascorriamo insieme È così bello
                Abbiamo qualcosa di speciale
              </p>

              <p>
                Migliora sempre più Ogni giorno il nostro amore trova nuovi modi
                per crescere Il tempo che trascorriamo insieme È così bello
                Abbiamo qualcosa di speciale
              </p>
            </Paragraph>
          </TabPane>

          <TabPane tab="Russian" key="lyrics_russian">
            <Paragraph id="lyricsContainer">
              <p>
                Мы вместе уже десять лет Но с каждым днём я люблю тебя всё
                больше Если бы я мог начать сначала Я, наверное, повторил бы
                всё, как и раньше
              </p>
              <p>
                Не хочу знать, что было бы, если бы ты меня никогда не полюбила
                Я не стал собой, пока не встретил тебя Не хочу знать, что было
                бы, если бы я никогда не почувствовал твоей любви Мне всё в тебе
                нравится
              </p>
              <p>
                Мы всё начинали в такой спешке Теперь мы можем сбавить темп
                Любви нужно время, нужно немного времени, повремени и ты Ведь
                она созревает как вино
              </p>
              <p>
                Всё становится только лучше! Каждый день наша любовь находит
                новые возможности Время когда мы вместе Так приятно У нас всё
                отлично
              </p>
              <p>
                И когда я думал, что в моём сердце больше не осталось места Я
                открыл эмоции, которых никогда не испытывал Ты просто
                потрясающая Не помню когда мне было скучно в последний раз
              </p>
              <p>
                Не хочу знать, что было бы, если бы ты меня никогда не полюбила
                Я не стал собой, пока не встретил тебя Не хочу знать, что было
                бы, если бы я никогда не почувствовал твоей любви Мне всё в тебе
                нравится
              </p>

              <p>
                Всё становится только лучше! Каждый день наша любовь находит
                новые возможности Время когда мы вместе Так приятно У нас всё
                отлично
              </p>

              <p>
                Всё становится только лучше! Каждый день наша любовь находит
                новые возможности Время когда мы вместе Так приятно У нас всё
                отлично
              </p>
            </Paragraph>
          </TabPane>
        </Tabs>
      </HeroSection>

      <HeroSection vheight={80} className="white-color text-center darkSection">
        <Title>Bio</Title>
        <Paragraph
          id="bioContainer"
          ellipsis={{
            rows: 15,
            expandable: true,
            /* suffix: string,
            symbol: ReactNode,
            tooltip: boolean | ReactNode,
            onExpand: function(event),
            onEllipsis: function(ellipsis), */
          }}
        >
          <p>
            Daði Freyr, the breakout star of last year’s Eurovision, is back –
            with an irresistible new song, another TikTok-ready dance routine
            and even more madcap ideas to get audiences behind the world’s
            biggest song contest and bring the trophy home to Iceland for the
            first time.
          </p>
          <p>
            10 Years is as catchy as its predecessor Think About Things, a
            global, celebrity-backed smash streamed more than 100 million times
            and silver certified in Britain, despite 2020’s contest being
            cancelled.
          </p>
          <p>
            Introduced by strings, set to slinky synths, funky bass and bright
            drums, led by Daði’s distinctive vocals and featuring a very
            special, 1000-voice choir, 10 Years treads a seductive tightrope
            between credible and camp. From the moment it leaked online days
            ahead of its debut on Icelandic TV in mid-March, it became the
            bookies’ favourite to win in Rotterdam in May.
          </p>
          <p>
            Not since Lordi and his monster mask 15 years ago has a Eurovision
            entry captured the public’s imagination like Daði and Gagnamagnið,
            the keytar-wielding, jumper-wearing, pixel-loving band of family and
            friends assembled solely for the contest. Never has an act come up
            with a concept as clever or inclusive or used technology as
            inventively to bring fans of all ages on board.
          </p>
          <p>
            “Eurovision for me is about so much more than a great song,” says
            Daði. “With 10 Years, I visualised the video before I wrote the
            song. I knew how the performance would look before it was finished
            and where we’d do the dance routine. I’ve spent the last few years
            of my life creating a Gagnamagnið world, of which the song is just
            one part.”
          </p>
          <p>
            From the artwork, outfits, DIY keytars and mandatory wind machine to
            TikTok challenges, the Instagram filter that last year let fans put
            their own face on a dancing Daði and the chance to purchase a jumper
            printed with your own pixelated head, Gagnamagnið’s wacky world has
            helped make Eurovision hip as much as Will Ferrell’s much-lauded
            Eurovision movie.
          </p>

          <img
            style={{ width: "50%" }}
            src="https://uc6a21e162bdff29d010daa83ea6.previews.dropboxusercontent.com/p/thumb/ABLl3kc6OYkiGIr492extUazrWgneLRLn69Eix-hdiG9cwO3vZWM0Q6Jv5SaNnTWOygt1Yh4kXS02P4Qm-7EDVyUaqrrFACTVHYnDNCkrHbBIhrmkESxuXjv1C-gAr-3Wh8PDT5kU8ZVu3ZutvwXeBa3ZzHK1Q5a0ojb5FPE_dlEj_WjfGDTYbsa8X9n1ZAp3whUqTLJmN-Lvh_38GpdjWZqWl0MQS969KadAlkVuQrD9WnlcjgDPxYSfMwJNH3noHU9sVMGQzU3C1lh-QFZlsVNlL8eEYTDaqI31iM-NaNlUSkl_h7BRU_loIdTlvRyyMjCC5tdo4wmNG0gv0iSO-voe3-OJh6Lub4hKqDcwoRBC5mL9YA2B-4en0dg3F49WMWcG-UDC3n-eQFu_-jJTHtsEeMVkURB_QsOxLbll_2mTQ/p.jpeg?fv_content=true&size_mode=5"
            alt=""
          />
          <p>
            For 2021, Daði has even more ambitious plans. There will be a beer
            from his favourite brewery, action figures, video games and a
            hologram. All have been conceived and co-created by Daði, some with
            help from fans sourced on social media.
          </p>
          <p>
            “We found people via Instagram to work on one of the video games I
            designed,” he says. “One is in Italy, another in Brazil, someone in
            South Africa and a guy in Iceland. The hologram I’ve wanted to do
            for a long time. By April, you’ll be able to have 3D me performing
            in your living room.”
          </p>
          <p>
            Everyone is welcome into Gagnamagnið’s world. In January, Daði took
            to Twitter to invite fans to be part of the choir on 10 Years.
            Around 1000 people from all over the globe took up the offer and
            every single one features on the song.
          </p>
          <p>
            “In fact we have 2000 voices in the choir because some people sent
            in multiple parts,” says Daði. “There were seven to choose from,
            which I released in a folder so that people could pick which suited
            them best. Everyone who sent a part back within the time frame was
            included, regardless of their standard.”
          </p>
          <p>
            So overwhelming was the response that Daði had to outsource the
            editing.
          </p>
          <p>
            “I did intend to do it myself,” he says, “but I didn’t foresee how
            much work it would be. It was a day and night job for maybe a month.
            Some were well recorded, others just on phones, so there was a lot
            of editing within each voice. I’ll admit some appear lower in the
            mix than others, but I wasn’t looking for professionals. That’s not
            the Eurovision spirit.”
          </p>
          <p>
            Fans will also have the opportunity to create their own 10 Years
            remix to accompany the official remix by a famous, as-yet-unnamed
            act when Daði makes the song’s stamps available online. Hot Chip,
            one of Daði’s favourite bands, recorded the acclaimed, club-friendly
            remix of Think About Things after their guitarist Alex Doyle
            declared himself a Daði fan on Twitter.
          </p>
          <p>
            Lyrically, 10 Years is a love song to Daði’s wife, Árný Fjóla, a
            member of Gagnamagnið, alongside one of his two sisters and three of
            their friends. ‘How does it keep getting better?’ he sings of their
            relationship.
          </p>
          <p>
            “Ha, I actually don’t know the answer to that,” says Daði. “It’s why
            I ask the question in the song. We’ve been together for ten years
            and married since 2018.
          </p>
          <p>
            “I checked with my wife that it was okay to write about her before I
            started on the lyrics. She has input on every song I make. I don’t
            send any music out until she hears it - her opinion is the most
            important to me, although sometimes I disagree.”
          </p>
          <p>
            Think About Things was inspired by the couple’s daughter, now nearly
            two. Clearly family is a theme, although no decisions were made
            about the new song until Eurovision confirmed in midOctober that all
            of 2020’s entries would get to go again. “10 Years was written for
            the competition,” says Daði. “That’s important. I wouldn’t write a
            song for myself then try to make it Eurovision. Or write for
            Eurovision and, if it didn’t happen, turn it into a song for me.
            From the start, I have Gagnamagnið in mind.
          </p>
          <p>
            “By the time I got to the twelfth demo, the concept had become
            clear. I knew I wanted to write about my life with my wife, that it
            had to be dramatic and start with strings. I even knew where we
            would use the wind machine on stage.”
          </p>
          <p>
            But it’s a different Daði entering this year’s Eurovision to the
            unknown-outside-Iceland who couldn’t have foreseen the furore and
            global fame which Think About Things would bring.
          </p>
          <p>
            “It’s definitely different, but I don’t feel any extra pressure,” he
            says. “I had as much to lose last year from my Icelandic audience.
            Yes, my audience is now on a bigger scale, but I’m as confident in
            10 Years as I was with Think About Things.”
          </p>
          <p>
            Has he heard the other entries? “Yes, Ukraine is my favourite, but
            maybe France the biggest threat.”
          </p>
          <p>
            The son of part-time musicians who met in an Icelandic marching band
            – his mum on flute, his dad on drums - Daði took up music full time
            in his teens after ditching dreams to become an artist (he drew
            Donald Duck comic books as a kid) or a basketball player (he’s a
            striking 6’10”). After a spell fronting a rock band with school
            friends (they covered Cream, Nirvana and The Darkness), he began
            making electronic music and moved to Berlin with his future wife to
            study production and audio engineering.
          </p>
          <p>
            A Eurovision fan, he formed Gagnamagnið for fun to compete in the
            Icelandic heats for the contest in 2017. When the band secured
            second place, Daði resolved to re-enter as a serious contender and,
            in 2019, was chosen to represent Iceland with Think About Things.
          </p>
          <p>
            The song found famous fans in Pink, Jennifer Garner, Russell Crowe
            and James Corden, while on TikTok its simple dance routine spread
            like wildfire. Think About Things was named Eurovision’s de facto
            winner when the contest was cancelled for the first time in its
            64-year history.
          </p>
          <p>
            Since the song exploded, Daði’s life has been almost all about
            Eurovision. In the past year, he has found time to release just two
            songs under his own name – Where We Wanna Be and Feel The Love, a
            collaboration with fellow Icelandic musician ÁSDÍS. He also charmed
            fans with much-demanded, tongue-in-cheek covers of songs from the
            Eurovision movie, The Story of Fire Saga, and played an acclaimed
            remote set for 2020’s Iceland Airwaves festival.
          </p>
          <p>
            “I’ve never made as little music for myself and I’m excited to get
            back to that,” says Daði’. “My Eurovision years are coming to an
            end. I can’t wait to get to Rotterdam, then it’s back to being me. I
            have a UK tour in November and, fingers crossed, some summer
            festivals first.”
          </p>
          <p>
            As for Gagnamagnið gigs? “There may be a few,” he teases. “Let’s see
            how the competition goes first.”
          </p>
        </Paragraph>
      </HeroSection>

      <HeroSection className="text-center" vheight={100}>
        <Title>
          <div>Images</div>
        </Title>

        <div className="image_gallery">
          <Image.PreviewGroup>
            <div className="imageWrapper">
              <Image
                src="/images/album/1_preview.jpg"
                preview={{
                  src: "/images/album/1.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/2_preview.jpg"
                preview={{
                  src: "/images/album/2.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/3_preview.jpg"
                preview={{
                  src: "/images/album/3.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/4_preview.jpg"
                preview={{
                  src: "/images/album/4.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/5_preview.jpg"
                preview={{
                  src: "/images/album/5.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/6_preview.jpg"
                preview={{
                  src: "/images/album/6.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/7_preview.jpg"
                preview={{
                  src: "/images/album/7.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/8_preview.jpg"
                preview={{
                  src: "/images/album/8.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/9_preview.jpg"
                preview={{
                  src: "/images/album/9.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/10_preview.jpg"
                preview={{
                  src: "/images/album/10.jpg",
                }}
              />
              <div className="photoCredit">Photo by Birta Rán</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/11_preview.jpg"
                preview={{
                  src: "/images/album/11.jpg",
                }}
              />
              <div className="photoCredit">Photo by Mummi Lú</div>
            </div>
            <div className="imageWrapper">
              <Image
                src="/images/album/12_preview.jpg"
                preview={{
                  src: "/images/album/12.jpg",
                }}
              />
              <div className="photoCredit">Photo by Thule Photo</div>
            </div>
          </Image.PreviewGroup>
        </div>
      </HeroSection>

      <HeroSection className="darkSection restricted" vheight={70}>
        <Title>
          <div className="text-center">Interviews</div>
        </Title>
        <Tabs defaultActiveKey="wiwibloggs" centered onChange={() => {}}>
          <TabPane tab="Wiwi bloggs" key="wiwibloggs">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="wiwibloggs"
                  src="https://www.youtube.com/embed/-lRfQlsPmqA"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="ESCXTRA" key="ESCXTRA">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="ESCXTRA"
                  src="https://www.youtube.com/embed/dH2_wMVAysU"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Laura Ortiz" key="LauraOrtiz">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Laura Ortiz"
                  src="https://www.youtube.com/embed/U8VLUoSl3u0"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </HeroSection>

      <HeroSection vheight={70}>
        <Title>
          <div className="text-center">Documentary</div>
        </Title>
        <Tabs defaultActiveKey="part1" centered onChange={() => {}}>
          <TabPane tab="Part 1" key="part1">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Part 1"
                  src="https://www.youtube.com/embed/JQaW3c-mkkw"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Part 2" key="part2">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Part 2"
                  src="https://www.youtube.com/embed/XDIqeVtn_JA"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </HeroSection>

      <HeroSection className="darkSection restricted" vheight={70}>
        <Title>
          <div className="text-center">Song Covers</div>
        </Title>
        <Tabs defaultActiveKey="arcade" centered onChange={() => {}}>
          <TabPane tab="Arcade" key="arcade">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Arcade"
                  src="https://www.youtube.com/embed/F6Dgo8SZJr0"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Jaja Ding Dong" key="jaja">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Jaja Ding Dong"
                  src="https://www.youtube.com/embed/jkV8SeNW_Nc"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Fuego" key="fuego">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Fuego"
                  src="https://www.youtube.com/embed/J8szy6FQ5_0"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Think About Things" key="thinkabout">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Think About Things (Accoustic)"
                  src="https://www.youtube.com/embed/zUyQneCsF4k"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Gleðibankinn" key="gledibankinn">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Gleðibankinn"
                  src="https://www.youtube.com/embed/OvMo7AmeSp0"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Shape of My Heart" key="shape">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Shape of My Heart"
                  src="https://www.youtube.com/embed/H8MncwMfmvo"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Various Eurovision Songs" key="varios">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Varios Eurovision Songs"
                  src="https://www.youtube.com/embed/bDgDLsBcjiw"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </HeroSection>

      <HeroSection className="restricted" vheight={70}>
        <Title>
          <div className="text-center">Dance Tutorial / Making of</div>
        </Title>
        <Tabs defaultActiveKey="dance" centered onChange={() => {}}>
          <TabPane tab="10 Years - Dance Tutorial" key="dance">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Arcade"
                  src="https://www.youtube.com/embed/0EwJ-p4tn_w"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Making Of - Think About Things" key="makingof">
            <div className="media_container">
              <div className="embed-container">
                <iframe
                  title="Making Of - Think About Things"
                  src="https://www.youtube.com/embed/LmdRU7FyHkA"
                  frameBorder="0"
                  allowFullScreen
                  defer
                ></iframe>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </HeroSection>

      <HeroSection
        className="text-center white-color"
        backgroundColor="black"
        vheight={20}
      >
        <Title className="staffTitle text-center white-color mt0">
          Iceland HOP for ESC 2021
        </Title>
        <div>Rúnar Freyr Gíslason</div>
        <div>
          <a href="mailto:runarfreyr@ruv.is">runarfreyr@ruv.is</a>
        </div>
        <br />
        <Title className="staffTitle text-center white-color mt0">MGMT</Title>
        <div>Árni Hrafn Kristmundsson</div>
        <div>
          <a href="mailto:arni@klapp.is">arni@klapp.is</a>
        </div>
      </HeroSection>
    </>
  );
}
